import { QRCode } from "react-qrcode-logo";
import "./index.css";
import logo from "./assets/img/logo.png";
import arButtonIcon from "./assets/img/ar-button-icon.webp";
import qr from "./assets/img/qr.png";
import tritophiaLogo from "./assets/img/tritophia-purple-without-text.png";
import paletteIcon from "./assets/img/palette-icon.svg";
import leftArrow from "./assets/img/left-arrow.svg";
import rightArrow from "./assets/img/right-arrow.svg";
import loadingJson from "./assets/loading.json";
import { isMobile } from "./utils/isMobile";
import { API_URL } from "./config/config";
import { useEffect, useRef, useState } from "react";
import { extractValuesFromUrl } from "./utils/extractValuesFromUrl";
import NotFound from "./NotFound";
import Lottie from "react-lottie-player";

function Preview() {
  const [modelPath, setModelPath] = useState(null);
  const [buttonText, setButtonText] = useState("");
  const [notFound, setNotFound] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [avaliableVariants, setAvaliableVariants] = useState([]);

  const url = window.location.href;
  const modelViewerRef = useRef<any>(null);

  const { integrationId, languageId, productId } = extractValuesFromUrl(url);

  const fetchData = async () => {
    if (languageId && integrationId && productId) {
      setLoading(true);

      const requestUrl = `${API_URL}/CustomerIntegrations/preview?productId=${productId}&languageId=${languageId}&integrationId=${integrationId}`;

      const response = await fetch(requestUrl);

      if (response.status === 200) {
        const data = await response.json();

        if (data.data) {
          setButtonText(data.data.texts.ar?.button);
          setModelPath(data.data.model.path);
        }
      } else {
        setNotFound(true);
      }

      setLoading(false);
    } else {
      setNotFound(true);
    }
  };

  function replaceHttpWithHttps(url: string) {
    return url.replace(/^http:\/\//i, "https://");
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const modelViewer: any = modelViewerRef.current;

    if (!modelViewer) {
      console.log("Model viewer or select not loaded yet.");
      return;
    }

    const handleModelLoad = () => {
      const names = modelViewer.availableVariants;
      setAvaliableVariants(names);
    };

    modelViewer.addEventListener("load", handleModelLoad);

    return () => {
      modelViewer.removeEventListener("load", handleModelLoad);
    };
  }, [modelPath]);

  const handleClickChangeVariant = (color: string) => {
    if (!modelViewerRef.current) {
      return;
    }

    modelViewerRef.current.variantName = color;
  };

  return (
    <div>
      <div className="absolute left-0 right-0 mx-auto h-full w-full select-none">
        <div className="relative h-full w-full">
          <div className="flex h-full w-full overflow-hidden bg-white">
            {loading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <Lottie
                  animationData={loadingJson}
                  loop
                  play
                  speed={2}
                  style={{ width: 280, height: 280 }}
                />
              </div>
            ) : notFound ? (
              <NotFound />
            ) : (
              <>
                <div
                  className="flex h-full flex-col items-center justify-center"
                  style={{ padding: "10px" }}
                >
                  {!isMobile() && (
                    <div id="background-container">
                      <div
                        style={{
                          width: "80%",
                          alignSelf: "center",
                        }}
                        className="flex flex-none flex-col items-center"
                      >
                        <div
                          style={{
                            height: "auto",
                            margin: "0 auto",
                            width: "100%",
                          }}
                        >
                          <QRCode
                            size={226}
                            quietZone={10}
                            eyeColor="#993BE1"
                            eyeRadius={7}
                            qrStyle="dots"
                            logoImage={tritophiaLogo}
                            logoHeight={70}
                            logoWidth={65}
                            logoPaddingStyle="square"
                            value={modelPath ? url : ""}
                          />
                        </div>

                        <div
                          style={{
                            padding: "20px 0 0 0px",
                            display: "flex",
                            flexDirection: "column",
                            gap: "1rem",
                            justifyContent: "center",
                            alignItems: "center",
                            width: 310,
                            margin: "0 auto",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              gap: "0.9rem",
                              marginRight: "auto",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flex: "none",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <img src={qr} alt="" width={45} height={45} />
                            </div>
                            <div
                              style={{
                                fontSize: 12,
                                color: "#737373",
                              }}
                            >
                              Artırılmış gerçeklik deneyimi için <br /> QR kodu
                              telefonunuzun <br /> kamerası ile taratın.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="relative h-full w-full flex-1">
                  <div
                    className="z-0 h-full w-full cursor-grab"
                    style={{
                      position: "relative",
                      width: "100%",
                      height: "100%",
                      overflow: "hidden",
                      pointerEvents: "auto",
                      touchAction: "none",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        position: "relative",
                      }}
                    >
                      {modelPath && (
                        <>
                          <model-viewer
                            alt="chair"
                            src={replaceHttpWithHttps(modelPath)}
                            ar
                            camera-controls
                            disable-tap
                            ref={modelViewerRef}
                            touch-action="none"
                            camera-orbit="45deg 75deg 9m"
                          >
                            <button
                              slot="ar-button"
                              style={{
                                backgroundColor: "#993BE1",
                                borderRadius: "16px",
                                color: "white",
                                padding: "8px 12px",
                                width: "200px",
                                border: "1px solid transparent",
                                position: "absolute",
                                bottom: "16px",
                                marginLeft: "auto",
                                marginRight: "auto",
                                left: 0,
                                right: 0,
                                textAlign: "center",
                                fontWeight: "bold",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                width={20}
                                style={{ marginRight: "8px" }}
                                src={arButtonIcon}
                                alt="ar-button"
                              />
                              <span style={{ fontSize: "14px" }}>
                                {buttonText}
                              </span>
                            </button>
                          </model-viewer>
                        </>
                      )}
                      <div
                        style={{ bottom: isMobile() ? "10%" : "4%" }}
                        id={"available-variants-container"}
                      >
                        {avaliableVariants.length > 0 && (
                          <div
                            style={{
                              overflow: "auto",
                              whiteSpace: "nowrap",
                              gap: "10px",
                              width: "100%",
                            }}
                          >
                            <button
                              type="button"
                              className="text-white bg-[#993BE1] text-center hover:bg-[#993BE1]/90 font-medium rounded-lg text-md px-2 py-2.5 text-center inline-flex items-center me-2 mb-2"
                              onClick={() => setIsOpen(!isOpen)}
                            >
                              Renk Değiştir
                              {isOpen ? (
                                <img
                                  src={leftArrow}
                                  width={14}
                                  height={14}
                                  className="ml-2"
                                  alt="leftArrow"
                                />
                              ) : (
                                <img
                                  src={rightArrow}
                                  width={14}
                                  height={14}
                                  className="ml-2"
                                  alt="rightArrow"
                                />
                              )}
                            </button>
                            {isOpen &&
                              avaliableVariants.map(
                                (avaliableVariant, index) => (
                                  <button
                                    key={index}
                                    type="button"
                                    style={{ border: "1px solid #993BE1" }}
                                    className="text-[#993BE1] hover:text-white hover:bg-purple-custom font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
                                    onClick={() =>
                                      handleClickChangeVariant(avaliableVariant)
                                    }
                                  >
                                    {avaliableVariant}
                                  </button>
                                )
                              )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  id={
                    isMobile()
                      ? "made-by-mobile-container"
                      : "made-by-container"
                  }
                >
                  <p
                    style={{
                      fontFamily: "Righteous",
                      marginRight: isMobile() ? "10px" : "20px",
                      marginBottom: "0px",
                    }}
                  >
                    made by
                  </p>
                  <img src={logo} alt="" width={isMobile() ? 120 : 200} />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Preview;
