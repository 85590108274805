export const extractValuesFromUrl = (url: string) => {
  const urlObj = new URL(url);

  const params = new URLSearchParams(urlObj.search);

  const integrationId = params.get("integrationId");
  const languageId = params.get("languageId");
  const productId = params.get("productId");

  return { integrationId, languageId, productId };
};
