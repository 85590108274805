const NotFound = () => {
  return (
    <div className="notFound">
      <div className="wrapper">
        <svg>
          <text x="50%" y="50%" dy=".35em" text-anchor="middle">
            Product Not Found
          </text>
        </svg>
      </div>
    </div>
  );
};

export default NotFound;
